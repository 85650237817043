<template>
  <div>
    <Grid :service="certifiedService"
          route_name="certifieds"
          route_grid_path="/dashboard/grid">
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="name" sort-key="name">{{ $t('certified.name') }}</vs-th>
        <vs-th key="total_emited" sort-key="total_emited">{{ $t('certified_emit.total_emited') }}</vs-th>
        <vs-th key="total_pending" sort-key="total_pending">{{ $t('certified_emit.total_pending') }}</vs-th>
        <vs-th key="total_fail" sort-key="total_fail">{{ $t('certified_emit.total_fail') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">
            <div> {{ tr.id }} </div>
          </vs-td>
          <vs-td width="40%" key="name">
            <div> {{ tr.name }}</div>
          </vs-td>
          <vs-td width="15%" key="total_emited">
            {{tr.total_emited}}
          </vs-td>
           <vs-td width="15%" key="total_pending">
            {{tr.total_pending}}
          </vs-td>
           <vs-td width="15%" key="total_fail">
            {{tr.total_fail}}
          </vs-td>
          <vs-td width="10%">
            <!--
            <feather-icon
              v-permission="'certified_emit.show'"
              icon="EyeIcon"
              svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
              v-tooltip.top="{
              content: $t('certified_emit.dashboard.open_dashboard'),
              delay: { show: 100, hide: 100 }
            }"
              @click="loadView(props.data[indextr].id)"/>
              -->
          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>
import CertifiedUserEmitService from '@/services/api/CertifiedUserEmitService'
import CertifiedService from '@/services/api/CertifiedService'

export default {
  props: {
    route_name: {
      type: String,
      default: 'certified_emit'
    },
  },
  data: () => ({
    selected: [],
    service: null,
    certifiedService: null,
  }),
  methods: {
    loadView(id){
      this.$router.push(`/${this.route_name}/${id}/dashboard`)
    }
  },
  beforeMount() {
    this.service = CertifiedUserEmitService.build(this.$vs)
    this.certifiedService = CertifiedService.build(this.$vs)
  }
}
</script>
