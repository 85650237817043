var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Grid", {
        attrs: {
          service: _vm.certifiedService,
          route_name: "certifieds",
          route_grid_path: "/dashboard/grid",
        },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                  _vm._v("ID"),
                ]),
                _c("vs-th", { key: "name", attrs: { "sort-key": "name" } }, [
                  _vm._v(_vm._s(_vm.$t("certified.name"))),
                ]),
                _c(
                  "vs-th",
                  {
                    key: "total_emited",
                    attrs: { "sort-key": "total_emited" },
                  },
                  [_vm._v(_vm._s(_vm.$t("certified_emit.total_emited")))]
                ),
                _c(
                  "vs-th",
                  {
                    key: "total_pending",
                    attrs: { "sort-key": "total_pending" },
                  },
                  [_vm._v(_vm._s(_vm.$t("certified_emit.total_pending")))]
                ),
                _c(
                  "vs-th",
                  { key: "total_fail", attrs: { "sort-key": "total_fail" } },
                  [_vm._v(_vm._s(_vm.$t("certified_emit.total_fail")))]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  { key: indextr, attrs: { data: tr } },
                  [
                    _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.id) + " ")]),
                    ]),
                    _c("vs-td", { key: "name", attrs: { width: "40%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.name))]),
                    ]),
                    _c(
                      "vs-td",
                      { key: "total_emited", attrs: { width: "15%" } },
                      [_vm._v(" " + _vm._s(tr.total_emited) + " ")]
                    ),
                    _c(
                      "vs-td",
                      { key: "total_pending", attrs: { width: "15%" } },
                      [_vm._v(" " + _vm._s(tr.total_pending) + " ")]
                    ),
                    _c(
                      "vs-td",
                      { key: "total_fail", attrs: { width: "15%" } },
                      [_vm._v(" " + _vm._s(tr.total_fail) + " ")]
                    ),
                    _c("vs-td", { attrs: { width: "10%" } }),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }